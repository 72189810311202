@import '../variables';

.works-images {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    opacity: 0;

    &.works-hover {
        opacity: 1;
    }

    .works-images-wr {
        position: relative;
        width: 100vh;
        height: 60vh;
        .works-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
            }
            &.works-hover {
                opacity: 1;
            }
        }
        .works-number {
            position: absolute;
            bottom: -4vh;
            right: 0;
        }
    }
}

.work-section {
    ul {
        margin-top: 5vw;
        display: table;
        float: right;
        color: $color_white;
        &:hover {
            color: $color_jet;
        }
        li {
            cursor: pointer;
            display: table;
            float: right;
            clear: both;
            @media (max-width: $mobile_version) {
                display: flex;
                margin-top: 5vw;
                a {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    margin-left: 5vw;
                }
            }
            img {
                opacity: 0.7;
                filter: brightness(0.5);
                max-height: 20vw;
                height: 100%;
                width: auto;
            }
            h3 {
                float: right;
            }
            p {
                float: right;
                opacity: 0;
                font-size: $text_size_tiny;
                line-height: $text_size_tiny * 1.2;
                letter-spacing: $text_spacing_light;
                position: relative;
                top: 1vw;
                right: 0.5vw;
                color: $color_white;
            }
            &:hover {
                h3 {
                    color: $color_white;
                }
                p {
                    opacity: 1;
                }
            }
        }
    }
}
