@import '../variables';

.overlay {
    background-color: transparent;
    mix-blend-mode: difference;
    pointer-events: none;
    z-index: 999;
    position: fixed;
    width: calc(100vw - calc(#{$padding_overlay} * 2));
    height: calc(100vh - calc(#{$padding_overlay_height} * 2));
    top: $padding_overlay_height;
    left: $padding_overlay;
    @media (max-width: $mobile_version) {
        width: calc(100vw - calc(#{$padding_overlay_MOB} * 2));
        height: calc(100vh - calc(#{$padding_overlay_height_MOB} * 2));
        top: $padding_overlay_height_MOB;
        left: $padding_overlay_MOB;
        position: absolute;
        mix-blend-mode: normal;
    }

    p {
        transform: rotate(-90deg) translate(-50%, calc(-285% - #{$overlay_gap}));
        pointer-events: auto;
        letter-spacing: $text_spacing_light;
        position: absolute;
        top: 0;
        left: 0;
        font-size: $text_size_normal;
        color: $color_white;
        width: auto;
        float: left;
        cursor: pointer;
        @media (max-width: $mobile_version) {
            transform: none;
            font-size: $text_size_normal_MOB;
            line-height: $text_size_normal_MOB * 1.4;
            letter-spacing: $text_spacing_light_MOB;
            overflow-wrap: normal;
            width: 1px;
        }
    }

    ul {
        position: absolute;
        pointer-events: auto;
        bottom: 0;

        &.socials {
            left: 0;
            transform: translate(calc(-100% - #{$overlay_gap}));

            @media (max-width: $mobile_version) {
                transform: none;
            }

            li {
                cursor: pointer;
                margin-top: 0.5vw;
                @media (max-width: $mobile_version) {
                    margin-top: 0;
                    margin-right: 6vw;
                    float: left;
                }
            }
            svg {
                fill: white;
                width: $social_icons;
                width: $social_icons;

                @media (max-width: $mobile_version) {
                    width: $social_icons_MOB;
                    width: $social_icons_MOB;
                }
            }

            .be svg {
                width: calc(#{$social_icons} + calc(#{$social_icons} * 0.2));
                height: calc(#{$social_icons} + calc(#{$social_icons} * 0.2));

                @media (max-width: $mobile_version) {
                    width: calc(#{$social_icons_MOB} + calc(#{$social_icons_MOB} * 0.2));
                    height: calc(#{$social_icons_MOB} + calc(#{$social_icons_MOB} * 0.2));
                    position: relative;
                    top: -0.5vw;
                }
            }
        }

        &.navbar {
            right: 0;
            font-weight: 400;
            li {
                font-size: $text_size_normal;
                line-height: ($text_size_normal * 2);
                color: $color_jet;
                font-family: $font_text;
                cursor: pointer;
                &:hover {
                    color: $color_light;
                }
            }
        }
    }
}

#cursor {
    opacity: 0;
    z-index: 99999;
    position: relative;
    mix-blend-mode: difference;

    .cursor {
        position: fixed;
        left: 0;
        top: 0;
        pointer-events: none;
    }

    .--small {
        width: 0.3vw;
        height: 0.3vw;
        left: -0.15vw;
        top: -0.15vw;
        border-radius: 50%;
        z-index: 999;
        opacity: 1;
        border: 0.08vw solid #fff;
        background-color: none;
        -webkit-transition: opacity 0.3s ease 0s, background-color 0.3s ease 0s, width 0.3s ease 0s, height 0.3s ease 0s, left 0.3s ease 0s, top 0.3s ease 0s;
        -moz-transition: opacity 0.3s ease 0s, background-color 0.3s ease 0s, width 0.3s ease 0s, height 0.3s ease 0s, left 0.3s ease 0s, top 0.3s ease 0s;
        -ms-transition: opacity 0.3s ease 0s, background-color 0.3s ease 0s, width 0.3s ease 0s, height 0.3s ease 0s, left 0.3s ease 0s, top 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s, background-color 0.3s ease 0s, width 0.3s ease 0s, height 0.3s ease 0s, left 0.3s ease 0s, top 0.3s ease 0s;
        transition: opacity 0.3s ease 0s, background-color 0.3s ease 0s, width 0.3s ease 0s, height 0.3s ease 0s, left 0.3s ease 0s, top 0.3s ease 0s;
    }

    .--follow {
        width: 3vw;
        height: 3vw;
        border-radius: 50%;
        z-index: 1000;
        opacity: 0.1;
        background-color: #fff;
        -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, left 0.2s ease-in-out,
            top 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out;
    }

    &.m--hover {
        .--small {
            border: 0vw solid #fff;
            background-color: #fff;
            width: 3vw;
            height: 3vw;
            left: -1.5vw;
            top: -1.5vw;
            opacity: 1;
        }

        .--follow {
            opacity: 0;
        }
    }
}

html:hover #cursor {
    opacity: 1;
}
