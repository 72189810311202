@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Black.eot');
    src: local('Neue Haas Unica Black'), local('NeueHaasUnica-Black'), url('../fonts/neuehaasunica/NeueHaasUnica-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Black.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Black.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Medium.eot');
    src: local('Neue Haas Unica Medium'), local('NeueHaasUnica-Medium'), url('../fonts/neuehaasunica/NeueHaasUnica-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Medium.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Medium.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic.eot');
    src: local('Neue Haas Unica Bold Italic'), local('NeueHaasUnica-BoldItalic'), url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ExtBlkIta.eot');
    src: local('Neue Haas Unica ExtBlk Ita'), local('NeueHaasUnica-ExtBlkIta'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtBlkIta.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtBlkIta.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtBlkIta.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtBlkIta.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBold.eot');
    src: local('Neue Haas Unica Extra Bold'), local('NeueHaasUnica-ExtraBold'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBold.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBold.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-MediumItalic.eot');
    src: local('Neue Haas Unica Medium Italic'), local('NeueHaasUnica-MediumItalic'),
        url('../fonts/neuehaasunica/NeueHaasUnica-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-MediumItalic.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-MediumItalic.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBoldItalic.eot');
    src: local('Neue Haas Unica ExtBd Ita'), local('NeueHaasUnica-ExtraBoldItalic'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Italic.eot');
    src: local('Neue Haas Unica Italic'), local('NeueHaasUnica-Italic'), url('../fonts/neuehaasunica/NeueHaasUnica-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Italic.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Italic.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Light.eot');
    src: local('Neue Haas Unica Light'), local('NeueHaasUnica-Light'), url('../fonts/neuehaasunica/NeueHaasUnica-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Light.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Light.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ExtLtIta.eot');
    src: local('Neue Haas Unica ExtLt Ita'), local('NeueHaasUnica-ExtLtIta'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtLtIta.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtLtIta.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtLtIta.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtLtIta.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Bold.eot');
    src: local('Neue Haas Unica Bold'), local('NeueHaasUnica-Bold'), url('../fonts/neuehaasunica/NeueHaasUnica-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Bold.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Bold.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ThinItalic.eot');
    src: local('Neue Haas Unica Thin Italic'), local('NeueHaasUnica-ThinItalic'), url('../fonts/neuehaasunica/NeueHaasUnica-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ThinItalic.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ThinItalic.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-LightItalic.eot');
    src: local('Neue Haas Unica Light Italic'), local('NeueHaasUnica-LightItalic'), url('../fonts/neuehaasunica/NeueHaasUnica-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-LightItalic.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-LightItalic.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ExtraLight.eot');
    src: local('Neue Haas Unica Extra Light'), local('NeueHaasUnica-ExtraLight'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraLight.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraLight.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Regular.eot');
    src: local('Neue Haas Unica Regular'), local('NeueHaasUnica-Regular'), url('../fonts/neuehaasunica/NeueHaasUnica-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Regular.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Regular.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBlack.eot');
    src: local('Neue Haas Unica Extra Black'), local('NeueHaasUnica-ExtraBlack'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBlack.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBlack.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic_1.eot');
    src: local('Neue Haas Unica Bold Italic'), local('NeueHaasUnica-BoldItalic'), url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic_1.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic_1.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-BoldItalic_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Neue Haas Unica';
    src: url('../fonts/neuehaasunica/NeueHaasUnica-Thin.eot');
    src: local('Neue Haas Unica Thin'), local('NeueHaasUnica-Thin'), url('../fonts/neuehaasunica/NeueHaasUnica-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Thin.woff2') format('woff2'), url('../fonts/neuehaasunica/NeueHaasUnica-Thin.woff') format('woff'),
        url('../fonts/neuehaasunica/NeueHaasUnica-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-Regular.eot');
    src: local('Platform Regular'), local('Platform-Regular'), url('../fonts/platform/Platform-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-Regular.woff2') format('woff2'), url('../fonts/platform/Platform-Regular.woff') format('woff'),
        url('../fonts/platform/Platform-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-MediumItalic.eot');
    src: local('Platform Medium Italic'), local('Platform-MediumItalic'), url('../fonts/platform/Platform-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-MediumItalic.woff2') format('woff2'), url('../fonts/platform/Platform-MediumItalic.woff') format('woff'),
        url('../fonts/platform/Platform-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-BoldItalic.eot');
    src: local('Platform Bold Italic'), local('Platform-BoldItalic'), url('../fonts/platform/Platform-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-BoldItalic.woff2') format('woff2'), url('../fonts/platform/Platform-BoldItalic.woff') format('woff'),
        url('../fonts/platform/Platform-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Platform Hairline';
    src: url('../fonts/platform/Platform-Hairline.eot');
    src: local('Platform Hairline'), local('Platform-Hairline'), url('../fonts/platform/Platform-Hairline.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-Hairline.woff2') format('woff2'), url('../fonts/platform/Platform-Hairline.woff') format('woff'),
        url('../fonts/platform/Platform-Hairline.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Platform Hairline';
    src: url('../fonts/platform/Platform-HairlineItalic.eot');
    src: local('Platform Hairline Italic'), local('Platform-HairlineItalic'), url('../fonts/platform/Platform-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-HairlineItalic.woff2') format('woff2'), url('../fonts/platform/Platform-HairlineItalic.woff') format('woff'),
        url('../fonts/platform/Platform-HairlineItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-Medium.eot');
    src: local('Platform Medium'), local('Platform-Medium'), url('../fonts/platform/Platform-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-Medium.woff2') format('woff2'), url('../fonts/platform/Platform-Medium.woff') format('woff'),
        url('../fonts/platform/Platform-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-RegularItalic.eot');
    src: local('Platform Regular Italic'), local('Platform-RegularItalic'), url('../fonts/platform/Platform-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-RegularItalic.woff2') format('woff2'), url('../fonts/platform/Platform-RegularItalic.woff') format('woff'),
        url('../fonts/platform/Platform-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-Bold.eot');
    src: local('Platform Bold'), local('Platform-Bold'), url('../fonts/platform/Platform-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-Bold.woff2') format('woff2'), url('../fonts/platform/Platform-Bold.woff') format('woff'),
        url('../fonts/platform/Platform-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-Light.eot');
    src: local('Platform Light'), local('Platform-Light'), url('../fonts/platform/Platform-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-Light.woff2') format('woff2'), url('../fonts/platform/Platform-Light.woff') format('woff'),
        url('../fonts/platform/Platform-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Platform';
    src: url('../fonts/platform/Platform-LightItalic.eot');
    src: local('Platform Light Italic'), local('Platform-LightItalic'), url('../fonts/platform/Platform-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/platform/Platform-LightItalic.woff2') format('woff2'), url('../fonts/platform/Platform-LightItalic.woff') format('woff'),
        url('../fonts/platform/Platform-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-MediumItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Medium Italic'), local('../fonts/graphik/GraphikLCG-MediumItalic'),
        url('../fonts/graphik/GraphikLCG-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-MediumItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-MediumItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-SuperItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Super Italic'), local('../fonts/graphik/GraphikLCG-SuperItalic'),
        url('../fonts/graphik/GraphikLCG-SuperItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-SuperItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-SuperItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-SuperItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-SemiboldItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Semibold Italic'), local('../fonts/graphik/GraphikLCG-SemiboldItalic'),
        url('../fonts/graphik/GraphikLCG-SemiboldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-SemiboldItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Black.eot');
    src: local('../fonts/graphik/Graphik LCG Black'), local('../fonts/graphik/GraphikLCG-Black'), url('../fonts/graphik/GraphikLCG-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/GraphikLCG-Black.woff2') format('woff2'), url('../fonts/graphik/GraphikLCG-Black.woff') format('woff'),
        url('../fonts/graphik/GraphikLCG-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Super.eot');
    src: local('../fonts/graphik/Graphik LCG Super'), local('../fonts/graphik/GraphikLCG-Super'), url('../fonts/graphik/GraphikLCG-Super.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/GraphikLCG-Super.woff2') format('woff2'), url('../fonts/graphik/GraphikLCG-Super.woff') format('woff'),
        url('../fonts/graphik/GraphikLCG-Super.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Thin.eot');
    src: local('../fonts/graphik/Graphik LCG Thin'), local('../fonts/graphik/GraphikLCG-Thin'), url('../fonts/graphik/GraphikLCG-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/GraphikLCG-Thin.woff2') format('woff2'), url('../fonts/graphik/GraphikLCG-Thin.woff') format('woff'),
        url('../fonts/graphik/GraphikLCG-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-ExtralightItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Extralight Italic'), local('../fonts/graphik/GraphikLCG-ExtralightItalic'),
        url('../fonts/graphik/GraphikLCG-ExtralightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-ExtralightItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-ExtralightItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-ExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Regular.eot');
    src: local('../fonts/graphik/Graphik LCG Regular'), local('../fonts/graphik/GraphikLCG-Regular'),
        url('../fonts/graphik/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-Regular.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-Regular.woff') format('woff'), url('../fonts/graphik/GraphikLCG-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-BoldItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Bold Italic'), local('../fonts/graphik/GraphikLCG-BoldItalic'),
        url('../fonts/graphik/GraphikLCG-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-BoldItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-BoldItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-LightItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Light Italic'), local('../fonts/graphik/GraphikLCG-LightItalic'),
        url('../fonts/graphik/GraphikLCG-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-LightItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-LightItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-BlackItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Black Italic'), local('../fonts/graphik/GraphikLCG-BlackItalic'),
        url('../fonts/graphik/GraphikLCG-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-BlackItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-BlackItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Medium.eot');
    src: local('../fonts/graphik/Graphik LCG Medium'), local('../fonts/graphik/GraphikLCG-Medium'), url('../fonts/graphik/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/GraphikLCG-Medium.woff2') format('woff2'), url('../fonts/graphik/GraphikLCG-Medium.woff') format('woff'),
        url('../fonts/graphik/GraphikLCG-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Light.eot');
    src: local('../fonts/graphik/Graphik LCG Light'), local('../fonts/graphik/GraphikLCG-Light'), url('../fonts/graphik/GraphikLCG-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/GraphikLCG-Light.woff2') format('woff2'), url('../fonts/graphik/GraphikLCG-Light.woff') format('woff'),
        url('../fonts/graphik/GraphikLCG-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Semibold.eot');
    src: local('../fonts/graphik/Graphik LCG Semibold'), local('../fonts/graphik/GraphikLCG-Semibold'),
        url('../fonts/graphik/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-Semibold.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-Semibold.woff') format('woff'), url('../fonts/graphik/GraphikLCG-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Bold.eot');
    src: local('../fonts/graphik/Graphik LCG Bold'), local('../fonts/graphik/GraphikLCG-Bold'), url('../fonts/graphik/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/GraphikLCG-Bold.woff2') format('woff2'), url('../fonts/graphik/GraphikLCG-Bold.woff') format('woff'),
        url('../fonts/graphik/GraphikLCG-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-Extralight.eot');
    src: local('../fonts/graphik/Graphik LCG Extralight'), local('../fonts/graphik/GraphikLCG-Extralight'),
        url('../fonts/graphik/GraphikLCG-Extralight.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-Extralight.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-Extralight.woff') format('woff'), url('../fonts/graphik/GraphikLCG-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-ThinItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Thin Italic'), local('../fonts/graphik/GraphikLCG-ThinItalic'),
        url('../fonts/graphik/GraphikLCG-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-ThinItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-ThinItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Graphik LCG';
    src: url('../fonts/graphik/GraphikLCG-RegularItalic.eot');
    src: local('../fonts/graphik/Graphik LCG Regular Italic'), local('../fonts/graphik/GraphikLCG-RegularItalic'),
        url('../fonts/graphik/GraphikLCG-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/graphik/GraphikLCG-RegularItalic.woff2') format('woff2'),
        url('../fonts/graphik/GraphikLCG-RegularItalic.woff') format('woff'), url('../fonts/graphik/GraphikLCG-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
