@import '../variables';

.contact-section {
    p {
        letter-spacing: $text_spacing_light;
        &.contact-link {
            cursor: pointer;
        }
    }
    .table {
        padding-bottom: 20vw;
        @media (max-width: $mobile_version) {
            padding-bottom: 30vw;
        }
        .last {
            flex: 0 0 100%;
            display: none;
            flex-direction: column;
            padding-top: 10vw;
            @media (max-width: $mobile_version) {
                display: flex;
            }
        }
        .slice:not(.row) {
            text-transform: uppercase;
        }
        .row {
            padding-bottom: 2vw;
            @media (max-width: $mobile_version) {
                padding-bottom: 8vw;
            }
        }
    }
}
