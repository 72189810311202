@import 'variables';
@import 'partials/overlay';

* {
    margin: 0;
    margin-block: 0;
}

::-moz-selection {
    /* Code for Firefox */
    background-color: $color_dawn;
}

::selection {
    background-color: $color_dawn;
}

ul {
    padding-inline-start: 0;
    list-style: none;
}

a {
    color: inherit;
    cursor: inherit;
    text-decoration: inherit;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    -webkit-box-shadow: inset 0 0 6px #e5e5e5;
    box-shadow: inset 0 0 6px #e5e5e5;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
::-webkit-scrollbar-thumb:window-inactive {
    background-color: #101010;
}
::-webkit-scrollbar-thumb:hover {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color_darker;
    color: $color_light;
    font-weight: normal;
    font-family: $font_main;
    letter-spacing: $text_spacing_none;
}

.scroll-html {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    scroll-behavior: smooth;
}

.scroll-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.noscroll {
        position: relative;
        overflow: initial;
        overflow-x: hidden;
    }
}

#wrapper {
    padding-right: $padding_main;
    @media (max-width: $mobile_version) {
        padding-right: $padding_main_MOB;
    }
}

.padding {
    padding-left: $padding_main;
    padding-right: $padding_right;
    @media (max-width: $mobile_version) {
        padding-right: $padding_right_MOB;
        padding-left: $padding_main_MOB;
    }
}

.table {
    display: flex;
    padding: $table_height 0;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: $mobile_version) {
        padding: $table_height_MOB 0;
    }
    .slice {
        flex: 2;
        &.big {
            flex: 3;
        }
        &.bigger {
            flex: 4;
        }
        &.row {
            flex: 0 0 100%;
        }
    }
}

body {
    cursor: default;
}

h1,
h2,
h3,
h4,
h5,
p,
div {
    cursor: inherit;
}

h2 {
    font-size: $text_size_title;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: $text_spacing_spaced;
    @media (max-width: $mobile_version) {
        font-size: $text_size_title_MOB;
        letter-spacing: $text_spacing_spaced_MOB;
        font-weight: 400;
    }
}

h3 {
    font-size: $text_size_big;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: $text_spacing_spaced;
    position: relative;
    @media (max-width: $mobile_version) {
        font-size: $text_size_large_MOB;
        line-height: $text_size_large_MOB * 1.3;
        letter-spacing: $text_spacing_normal_MOB;
    }
    span {
        font-size: $text_size_med;
        color: $color_jet;
        position: absolute;
        top: 0.25vw;
        right: calc(-0.5vw - #{$text_size_med});
        @media (max-width: $mobile_version) {
            top: -1vw;
            font-size: $text_size_large_MOB;
            right: calc(-3vw - #{$text_size_med_MOB});
        }
    }
}

p {
    font-size: $text_size_normal;
    @media (max-width: $mobile_version) {
        font-size: $text_size_normal_MOB;
        line-height: $text_size_normal_MOB * 1.3;
    }
}
.pre {
    white-space: pre;
}

.txtal-r {
    text-align: right;
}

.txtal-c {
    text-align: center;
}

.relative {
    position: relative;
}

.btn {
    cursor: pointer;
    font-size: $text_size_med;
    letter-spacing: $text_spacing_zero;
    padding: 0.8vw 2vw;
    color: #8f8f8f;
    background-color: $color_dawn;
    float: left;
    &:hover {
        background-color: $color_dark;
        color: #dfdfdf;
    }
    @media (max-width: $mobile_version) {
        font-size: $text_size_normal_MOB;
        padding: 2.3vw 5vw;
    }
}

#popup {
    opacity: 0;
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: grid;
    &.opened {
        opacity: 1;
        pointer-events: all;
    }
    .background {
        content: ' ';
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .close {
        top: 2%;
        right: 1.5%;
        padding: 0.5% 1%;
        position: absolute;
        z-index: 1;
        color: $color_jet;
        font-size: $text_size_large;
        @media (max-width: $mobile_version) {
            font-size: $text_size_title_MOB;
            top: 1%;
            right: 4%;
        }
        .x {
            transform: rotate(45deg);
        }
        &:hover {
            color: $color_white;
        }
    }
    img {
        position: absolute;
        object-fit: contain;
        max-width: 90%;
        max-height: 90%;
        justify-self: center;
        align-self: center;
        z-index: 1;
    }
}

.ease {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

// .ease-works {
//     transition: opacity 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
// }

.ease-works {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ease-carousel {
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

* {
    cursor: none !important;
}

.mobile {
    display: none;
}

.pc {
    display: block;
}

@media (max-width: $mobile_version) {
    .pc {
        display: none;
    }

    .mobile {
        display: block;
    }
}
