@import '../variables';

.project-hero {
    padding: 9.5vh 0 0 12vw;
    position: relative;
    @media (max-width: $mobile_version) {
        padding: 0 0 $table_height_MOB 5vw;
        height: calc(100vh - #{$table_height_MOB});
    }
    .carousel .slide {
        height: 66vh;
    }
    .project-info {
        height: 22vh;
        width: 75vw;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        @media (max-width: $mobile_version) {
            position: absolute;
            color: $color_white;
            left: 12vw;
            width: 85vw;
            bottom: 40vw;
            flex-direction: column;
            align-items: start;
        }

        .project-title {
            min-width: 40%;
            max-width: 50%;
            @media (max-width: $mobile_version) {
                min-width: initial;
                max-width: initial;
                width: auto;
            }
            .project-name {
                text-transform: uppercase;
                font-size: $text_size_big;
                font-weight: 500;
                font-family: $font_text;
                line-height: ($text_size_big * 1.2);
                @media (max-width: $mobile_version) {
                    font-size: $text_size_big_MOB;
                    line-height: ($text_size_big_MOB * 1.2);
                }
            }
            .project-subtitle {
                font-size: $text_size_normal;
                font-weight: 400;
                letter-spacing: $text_spacing_light;
                color: $color_jet;
                @media (max-width: $mobile_version) {
                    font-size: $text_size_normal_MOB;
                    color: inherit;
                }
            }
        }
        .project-description {
            min-width: 50%;
            padding: 0 5vw;
            letter-spacing: $text_spacing_zero;
            word-wrap: break-word;
            font-family: $font_text;
            font-size: $text_size_normal;
            line-height: ($text_size_normal * 1.3);
            flex-grow: 1;
            @media (max-width: $mobile_version) {
                padding: 0;
                font-size: $text_size_tiny_MOB;
                line-height: ($text_size_tiny_MOB * 1.3);
                font-weight: 300;
                letter-spacing: $text_spacing_light;
            }
        }
    }
}

.project-stats {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -$text_size_large;
    &.less {
        justify-content: space-evenly;
    }
    .project-stat {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .stat-title {
            font-family: $font_text;
            font-weight: 600;
            font-size: $text_size_large;
            @media (max-width: $mobile_version) {
                font-size: $text_size_large_MOB;
                line-height: $text_size_large_MOB * 1.2;
            }
        }
        .stat-info {
            font-family: $font_text;
            font-size: $text_size_normal;
            color: $color_jet;
            margin-top: 0.6vw;
            @media (max-width: $mobile_version) {
                margin-top: 2vw;
                height: 2.4 * $text_size_normal_MOB;
                font-size: $text_size_normal_MOB;
                line-height: $text_size_normal_MOB * 1.2;
            }
        }
    }
}

@media (max-width: $mobile_version) {
    #overlay,
    #project-page #mobileoverlay .socials {
        display: none;
    }

    ul.socials {
        margin-top: 7.5vw;
        left: 0;

        li {
            cursor: pointer;
            margin-top: 0.5vw;
            @media (max-width: $mobile_version) {
                margin-top: 0;
                margin-right: 6vw;
                float: left;
            }
        }
        svg {
            fill: white;
            width: $social_icons;
            width: $social_icons;

            @media (max-width: $mobile_version) {
                width: $social_icons_MOB;
                width: $social_icons_MOB;
            }
        }

        .be svg {
            width: calc(#{$social_icons} + calc(#{$social_icons} * 0.2));
            height: calc(#{$social_icons} + calc(#{$social_icons} * 0.2));

            @media (max-width: $mobile_version) {
                width: calc(#{$social_icons_MOB} + calc(#{$social_icons_MOB} * 0.2));
                height: calc(#{$social_icons_MOB} + calc(#{$social_icons_MOB} * 0.2));
                position: relative;
                top: -0.5vw;
            }
        }
    }
}

.project-details-image {
    width: 45%;
    @media (max-width: $mobile_version) {
        width: 100%;
        margin-top: 30vw;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.project-details {
    padding: 9.5vh 0 $table_height 12vw;
    width: 75vw;
    display: flex;
    @media (max-width: $mobile_version) {
        padding: 2vw 0 $table_height 0;
        width: 90vw;
        display: block;
    }
    .project-details-text {
        width: 40%;
        padding: 0 10%;
        @media (max-width: $mobile_version) {
            width: 90%;
            padding: 0;
            padding-left: 10%;
        }
        .project-detail {
            h2 {
                font-family: $font_text;
                font-weight: 600;
                font-size: $text_size_med;
                letter-spacing: $text_spacing_zero;
                margin-top: 4vw;
                @media (max-width: $mobile_version) {
                    font-size: $text_size_med_MOB;
                    margin-top: 10vw;
                }
            }
            p {
                margin-top: 2vw;
                font-weight: normal;
                font-family: $font_text;
                color: $color_jet;
                font-size: $text_size_med;
                line-height: $text_size_med * 1.4;
                letter-spacing: $text_spacing_none;
                @media (max-width: $mobile_version) {
                    font-size: $text_size_normal_MOB;
                    line-height: $text_size_normal_MOB * 1.4;
                    letter-spacing: $text_spacing_zero;
                    margin-top: 8vw;
                }
            }
        }
        .btn {
            margin-top: 4vw;
            @media (max-width: $mobile_version) {
                margin-top: 10vw;
            }
        }
    }
}

#carousel-buttons {
    height: 4vh;
    width: 80vw;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    @media (max-width: $mobile_version) {
        display: none;
    }
    .carousel-btn {
        height: 4px;
        width: 5vw;
        margin-right: 0.3%;
        padding: 1vh 0;
        position: relative;
        cursor: pointer;
        .background {
            width: 100%;
            height: 100%;
            background-color: $color_dusk;
        }
        &.active .background {
            background-color: $color_light;
        }
    }
}

.project-preview {
    display: none;
    @media (max-width: $mobile_version) {
        display: block;
    }
    img {
        opacity: 0.7;
        filter: brightness(0.5);
        position: absolute;
        height: 60vh;
        bottom: 20vh;
        left: 35vw;
    }
}

.mobile-carousel {
    position: relative;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 20vw;
    @media (max-width: $mobile_version) {
        display: flex;
    }
    .carousel-img {
        float: left;
        position: relative;
        overflow: hidden;
        width: auto;
        height: auto;
        margin-right: 2%;
        max-width: 31%;
        margin-bottom: 2%;
        max-height: 27vw;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

#project-carousel {
    height: 63vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    .carousel-wrapper {
        position: relative;
        height: 100%;
        width: max-content;
        display: inline-block;
        .carousel-img {
            float: left;
            width: 63vh;
            height: 63vh;
            margin-right: 0.8vw;
            position: relative;
            overflow: hidden;
            @media (max-width: $mobile_version) {
                width: auto;
                height: auto;
                margin-right: 2%;
                max-width: 23%;
                &:last-child {
                    margin-right: 0%;
                }
            }
            cursor: pointer;
            &:first-child {
                width: 60vw;
                @media (max-width: $mobile_version) {
                    width: 30vw;
                }
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .view {
            .motion {
                width: 100%;
                height: 100%;
            }
            &::after {
                content: '+';
                color: rgba(255, 255, 255, 0);
                background-color: rgba(0, 0, 0, 0);
                overflow: hidden;
                z-index: 1000;
                position: absolute;
                top: 0;
                font-family: 'Graphik LCG';
                left: 0;
                font-size: $text_size_title;
                font-weight: 1;
                width: 100%;
                height: 100%;
                display: flex;
                margin: auto;
                align-items: center;
                justify-content: center;
                -webkit-transition: all 0.3s ease 0s;
                -moz-transition: all 0.3s ease 0s;
                -ms-transition: all 0.3s ease 0s;
                -o-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;
            }
            &:hover::after {
                color: rgba(255, 255, 255, 0.9);
                background-color: rgba(0, 0, 0, 0.7);
            }

            &:hover img {
                opacity: 0.5;
                transform: scale(1.1);
            }
        }
    }
}
