@import '../variables';

.intro-section {
    padding: $padding_overlay_height 0;
    @media (max-width: $mobile_version) {
        height: 80vh;
    }
    h1 {
        display: table;
        padding-left: $padding_overlay;
        color: $color_light;
        font-weight: $font_weight_heading;
        font-family: $font_off;
        font-size: $text_size_heading;
        line-height: $line_height_heading;
        text-transform: uppercase;
        white-space: nowrap;
        transform-origin: bottom left;
        @media (max-width: $mobile_version) {
            font-size: $text_size_heading_MOB;
            line-height: $line_height_heading_MOB;
            padding-left: 0;
            transform: rotate(90deg) translate(calc((-3 * #{$line_height_heading_MOB}) + 15vh), calc(#{$padding_overlay_MOB} * -0.65));
        }
        div {
            float: left;
            position: relative;
        }
    }
}

.lineParent {
    overflow: hidden;
    width: 150%;
    .lineChildren {
        transform: translate(0, 500px);
    }
}
