// Media Variables
$mobile_version: 1200px;

// General Variables
$color_white: #fff;
$color_light: #e7e7e5;
$color_jet: #7c7c7c;
$color_milk: #616161;
$color_dusk: #373737;
$color_dawn: #272727;
$color_dark: #191919;
$color_darker: #101010;
$color_black: #000;

$font_main: 'Neue Haas Unica';
$font_off: 'Platform';
$font_text: 'Graphik LCG';

$font_weight_heading: 500;

// PC Numbers

$text_size_tiny: 0.6vw;
$text_size_normal: 0.8vw;
$text_size_med: 1.2vw;
$text_size_large: 2vw;
$text_size_big: 2.5vw;
$text_size_title: 5vw;
$text_size_heading: 10.5vw;

$line_height_heading: 10vw;

$text_spacing_right: -1px;
$text_spacing_zero: 0px;
$text_spacing_none: 1px;
$text_spacing_light: 0.1vw;
$text_spacing_normal: 0.2vw;
$text_spacing_spaced: 0.4vw;

$padding_main: 15vw;
$padding_right: 5vw;
$padding_overlay: 10vw;
$padding_overlay_height: 5vw;

$table_height: 10vw;
$row_padding: 2vw;
$overlay_gap: 1.6vw;
$social_icons: 1vw;

// Mobile Numbers

$text_size_tiny_MOB: 3vw;
$text_size_normal_MOB: 3.5vw;
$text_size_med_MOB: 4.5vw;
$text_size_large_MOB: 7vw;
$text_size_big_MOB: 12vw;
$text_size_title_MOB: 12vw;
$text_size_heading_MOB: 9vh;

$line_height_heading_MOB: 8.5vh;

$text_spacing_right_MOB: -1px;
$text_spacing_zero_MOB: 0px;
$text_spacing_none_MOB: 1px;
$text_spacing_light_MOB: 0.3vw;
$text_spacing_normal_MOB: 0.6vw;
$text_spacing_spaced_MOB: 1.2vw;

$padding_main_MOB: 10vw;
$padding_right_MOB: 0;
$padding_overlay_MOB: 10vw;
$padding_overlay_height_MOB: 10vw;

$table_height_MOB: 20vw;
$row_padding_MOB: 7vw;
$overlay_gap_MOB: 1.6vw;
$social_icons_MOB: 7vw;
