@import '../variables';

.about-section {
    img {
        width: 100%;
        z-index: -1;
        position: relative;
        opacity: 0.9;
        filter: brightness(0.7);
        height: auto;
        @media (max-width: $mobile_version) {
            transform: scale(1.4) translate(25%);
        }
    }
    .table {
        margin-top: 5vw;
        @media (max-width: $mobile_version) {
            width: 70vw;
            margin-top: 25vw;
            .slice {
                flex: 0 0 100%;
            }
            h2 {
                margin: 7vw 0;
            }
        }
        .small {
            p {
                padding: $row_padding 0;
                font-size: $text_size_med;
                @media (max-width: $mobile_version) {
                    padding: $row_padding_MOB 0;
                    font-size: $text_size_med_MOB;
                }
            }
        }
    }
}
